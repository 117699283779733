<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="categories"
      :fields="fields"
      responsive
    >
      <template #cell(active_status)="data">
        <b-badge :variant="data.value ? 'success' : 'danger'">
          {{ data.value ? $t('active') : $t('unactive') }}
        </b-badge>
      </template>
      <template #cell(tools)="data">
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <b-dropdown-item
            :to="{ name: 'editCategory', params: { categoryId: data.item.id } }"
          >{{ $t('edit') }}</b-dropdown-item>
          <b-dropdown-item @click="deactivate(data.item)">
            <span
              :class="data.item.active_status ? 'text-danger' : 'text-success'"
            >
              {{
                data.item.active_status ? $t('deactivate') : $t('reactivate')
              }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { BTable } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'active_status',
          label: this.$t('status'),
        },
        {
          key: 'tools',
          label: 'herramientas',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('categories', ['categories']),
  },

  methods: {
    ...mapActions('categories', ['deactivateCategory', 'fetchCategories']),
    deactivate(category) {
      this.deactivateCategory(category).then(() => {
        this.fetchCategories({ params: null })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
