<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('categories') }}
        </h1>
        <b-button
          :to="{ name: 'new-category' }"
          variant="primary"
        >
          {{ $t('register', { type: $t('categories') }) }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <categories-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import CategoriesTable from '@/components/categories/CategoriesTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CategoriesTable,
  },
  mounted() {
    this.fetchCategories({ by_active_status: null })
  },
  methods: {
    ...mapActions('categories', ['fetchCategories']),
  },
}
</script>

<style lang="scss" scoped></style>
